<template>
    <!-- 新版服務商審核流程 -->
    <main>
        <LoadingComponent :isLoading="loading" />
        <section v-if="!loading" class="bg-white rounded-lg mt-4 px-[8%] py-5">
            <!-- <pre>{{ data }}</pre> -->
            <article>
                <h3 class="text-2xl font-bold">個人資料</h3>
                <ul class="flex mt-5">
                    <li class="flex-1">
                        <div>
                            <h5 class="text-base text-gray-400">顯示名稱</h5>
                            <p class="mt-5 text-lg">{{ data.user.name }}</p>
                        </div>
                    </li>
                    <li class="flex-1">
                        <div>
                            <h5 class="text-base text-gray-400">真實姓名</h5>
                            <p class="mt-5 text-lg">
                                {{
                                    $isEmpty(data.user.real_name)
                                        ? "未填寫"
                                        : data.user.real_name
                                }}
                            </p>
                        </div>
                    </li>
                </ul>
                <ul class="flex mt-5">
                    <li class="flex-1">
                        <div>
                            <h5 class="text-base text-gray-400">Email</h5>
                            <p class="mt-5 text-lg">
                                {{
                                    $isEmpty(data.user.email)
                                        ? "未填寫"
                                        : data.user.email
                                }}
                            </p>
                        </div>
                    </li>
                    <li class="flex-1">
                        <div>
                            <h5 class="text-base text-gray-400">出生日期</h5>
                            <p class="mt-5 text-lg">
                                {{
                                    $isEmpty(data.user.birthday)
                                        ? "未填寫"
                                        : $moment(data.user.birthday).format(
                                              "YYYY/MM/DD"
                                          )
                                }}
                            </p>
                        </div>
                    </li>
                </ul>
            </article>
            <article class="mt-10">
                <div>
                    <h3 class="text-2xl font-bold">
                        申請資料<span
                            v-if="data.status !== 0"
                            class="text-red-500"
                            >({{
                                $isEmpty(
                                    statusOptions.find(
                                        (item) => item.value === data.status
                                    )
                                )
                                    ? null
                                    : statusOptions.find(
                                          (item) => item.value === data.status
                                      ).label
                            }})</span
                        >
                    </h3>
                    <h5 class="mt-5 text-base text-gray-400">
                        個人正面照 3 張
                    </h5>
                    <ul class="flex mt-5">
                        <li
                            v-for="(item, index) in data.attachment.photos"
                            :key="item.id"
                            :class="
                                index !== 0 &&
                                index !== data.attachment.photos.length - 1
                                    ? 'mx-2'
                                    : ''
                            "
                            class="flex-1"
                        >
                            <img
                                class="w-full rounded-lg"
                                :src="item.url"
                                :alt="item.id"
                            />
                        </li>
                    </ul>
                </div>
                <div class="mt-5">
                    <ul class="flex">
                        <li class="flex-1">
                            <div>
                                <h5 class="text-base text-gray-400">
                                    Facebook
                                </h5>
                                <p class="mt-5 text-lg">
                                    {{
                                        $isEmpty(data.attachment.social)
                                            ? "未填寫"
                                            : $isEmpty(
                                                  data.attachment.social
                                                      .facebook
                                              )
                                            ? "未填寫"
                                            : data.attachment.social.facebook
                                    }}
                                </p>
                            </div>
                        </li>
                        <li class="flex-1">
                            <div>
                                <h5 class="text-base text-gray-400">
                                    Instagram
                                </h5>
                                <p class="mt-5 text-lg">
                                    {{
                                        $isEmpty(data.attachment.social)
                                            ? "未填寫"
                                            : $isEmpty(
                                                  data.attachment.social
                                                      .instagram
                                              )
                                            ? "未填寫"
                                            : data.attachment.social.instagram
                                    }}
                                </p>
                            </div>
                        </li>
                    </ul>
                    <ul class="flex mt-5">
                        <li class="flex-1">
                            <div>
                                <h5 class="text-base text-gray-400">LINE ID</h5>
                                <p class="mt-5 text-lg">
                                    {{
                                        $isEmpty(data.attachment.social)
                                            ? "未填寫"
                                            : $isEmpty(
                                                  data.attachment.social.line
                                              )
                                            ? "未填寫"
                                            : data.attachment.social.line
                                    }}
                                </p>
                            </div>
                        </li>
                        <li class="flex-1">
                            <div>
                                <h5 class="text-base text-gray-400">
                                    經紀代碼(選填)
                                </h5>
                                <p class="mt-5 text-lg">
                                    {{
                                        $isEmpty(data.user.broker_id)
                                            ? "未填寫"
                                            : $isEmpty(data.user.broker)
                                            ? null
                                            : data.user.broker.login
                                    }}
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </article>
            <article class="mt-10">
                <div>
                    <h3 class="text-2xl font-bold">視訊驗證時間</h3>
                    <div class="mt-5">
                        <span>{{
                            $isEmpty(data.attachment.interview)
                                ? "未填寫"
                                : $moment(
                                      data.attachment.interview.date
                                  ).format("YYYY/MM/DD") +
                                  replaceWeekDayToChinese(
                                      $moment(
                                          data.attachment.interview.date
                                      ).weekday()
                                  )
                        }}</span>
                        <span>
                            {{
                                $isEmpty(data.attachment.interview)
                                    ? ""
                                    : data.attachment.interview.time === "14-18"
                                    ? `下午時段(${data.attachment.interview.time})`
                                    : `晚上時段(${data.attachment.interview.time})`
                            }}
                        </span>
                    </div>
                </div>
            </article>
            <article v-if="data.status === 0" class="flex justify-center my-5">
                <div
                    v-for="option in statusOptions"
                    :key="option.value"
                    class="flex justify-center flex-1"
                >
                    <button
                        class="red-btn-500 text-md min-w-[200px]"
                        @click="openDialog(option.value)"
                    >
                        {{ option.label }}
                    </button>
                </div>
            </article>
            <article class="flex justify-center my-5">
                <button
                    class="black-btn-outline-lg text-md min-w-[200px]"
                    @click="backList"
                >
                    返回列表
                </button>
            </article>
        </section>

        <!-- 確認是否取消預訂彈窗 -->
        <my-dialog
            :showDialog="showDialog"
            :customClass="'top-1/4 z-[70]'"
            :customBlackBgClass="'z-[60]'"
            @onCloseDialog="closeDialog"
        >
            <div
                class="bg-white rounded-lg min-w-[500px] p-5 text-center relative"
            >
                <div
                    v-if="status === 1"
                    class="w-full p-5 bg-white rounded-lg md:p-10"
                >
                    <h5 class="text-xl font-bold">審核通過</h5>
                    <p class="my-5 text-sm text-gray-400">
                        通過審核後，將無法回到待審核狀態
                    </p>
                    <div class="flex justify-center mt-5">
                        <button
                            :disabled="loading"
                            class="border border-black px-3 py-0.5 rounded mr-5 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:text-white disabled:border-gray-300"
                            @click.prevent="closeDialog"
                        >
                            關閉
                        </button>
                        <button
                            v-loading="loading"
                            :disabled="loading"
                            class="bg-red-500 px-3 py-0.5 rounded text-white disabled:bg-gray-300 disabled:cursor-not-allowed"
                            @click.prevent="update(route.params.id)"
                        >
                            確認
                        </button>
                    </div>
                </div>
                <div v-else>
                    <div
                        class="absolute text-2xl cursor-pointer right-10 top-5"
                        @click="closeDialog"
                    >
                        <font-awesome-icon :icon="['fas', 'times']" />
                    </div>
                    <h5 class="mt-10 text-xl font-bold">
                        {{
                            statusOptions.find((item) => item.value === status)
                                ? statusOptions.find(
                                      (item) => item.value === status
                                  ).label
                                : null
                        }}
                    </h5>
                    <p class="my-5 text-sm text-gray-400">
                        申請成為服務商{{
                            status === -2 ? "資料不完整" : "未通過"
                        }}
                    </p>
                    <el-input
                        v-model="form.comment"
                        type="textarea"
                        :rows="5"
                        :placeholder="
                            status === -2
                                ? '請詳細說明未完整資料'
                                : '請輸入審核未通過原因'
                        "
                    ></el-input>
                    <button
                        v-loading="loading"
                        :disabled="loading || $isEmpty(form.comment)"
                        class="px-3 py-2 my-5 rounded-lg myDisabled red-btn-500"
                        @click="update(route.params.id)"
                    >
                        確認送出
                    </button>
                </div>
            </div>
        </my-dialog>
    </main>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
import LoadingComponent from "@/components/Loading.vue";
// 導入自定義彈窗組件
import myDialog from "@/components/Dialog.vue";
import store from "@/store";
const __sfc_main = {
  name: "ApplyToProviderData"
};
__sfc_main.setup = (__props, __ctx) => {
  const {
    proxy
  } = getCurrentInstance();
  const route = useRoute();
  const router = useRouter();
  const loading = ref(false);
  // 轉換星期幾數字為中文數字
  function replaceWeekDayToChinese(val) {
    switch (val) {
      case 0:
        return "(日)";
      case 1:
        return "(ㄧ)";
      case 2:
        return "(二)";
      case 3:
        return "(三)";
      case 4:
        return "(四)";
      case 5:
        return "(五)";
      case 6:
        return "(六)";
    }
  }
  // 申請資料
  const data = ref({
    user: {
      name: "",
      real_name: null,
      email: null,
      birthday: null,
      broker: {}
    },
    attachment: {
      photos: [],
      interview: {},
      social: {
        facebook: null,
        instagram: null,
        line: null
      }
    }
  });
  // 表單資料
  const form = ref({});
  // 表單 dom
  const formRefDom = ref(null);
  // 判斷是否顯示彈窗
  const showDialog = ref(false);
  // 可選擇的審核狀態
  const statusOptions = ref([{
    label: "審核不通過",
    value: -1
  }, {
    label: "資料不完整",
    value: -2
  }, {
    label: "審核通過",
    value: 1
  }]);
  // 選擇的審核狀態
  const status = ref(null);

  /**
   * 返回列表
   */
  function backList() {
    router.go(-1);
  }
  /**
   * 取得資料
   * @param { type Number(數字) } id 申請 id
   */
  async function getData(id) {
    loading.value = true;
    try {
      const {
        data: applyData
      } = await proxy.$api.GetApplyToProviderByPhpApi(id);
      data.value = applyData;
    } catch (err) {
      console.log("err =>", err);
      store.dispatch("apiStore/errorCallback", {
        err
      });
    } finally {
      loading.value = false;
    }
  }

  /**
   * 更新資料
   */
  async function update(id) {
    loading.value = true;
    try {
      form.value.status = status.value;
      if (status.value === 1) {
        await proxy.$api.UpdateApplayToProviderApi({
          id,
          data: form.value
        });
      } else {
        await proxy.$api.UpdateApplayToProviderApi({
          id,
          data: form.value
        });
      }
      await getData(id);
      proxy.$message({
        type: "success",
        message: "更新成功"
      });
      closeDialog();
      router.go(-1);
    } catch (err) {
      store.dispatch("apiStore/errorCallback", {
        err
      });
    } finally {
      loading.value = false;
    }
  }
  /**
   * 跳轉 會員 個人資料
   * @param { type String(字串) } id 會員 id
   */
  function goToUserProfile(id) {
    window.open(`/#/user_list/provider_update/${id}/Profile`);
  }
  /**
   * 開啟彈窗
   * @param { type Number(數字) } selectStatus 審核狀態
   */
  function openDialog(selectStatus) {
    showDialog.value = true;
    status.value = selectStatus;
  }

  /**
   * 關閉彈窗
   */
  function closeDialog() {
    showDialog.value = false;
    status.value = null;
  }
  onMounted(async () => {
    await getData(route.params.id);
  });
  return {
    route,
    loading,
    replaceWeekDayToChinese,
    data,
    form,
    showDialog,
    statusOptions,
    status,
    backList,
    update,
    openDialog,
    closeDialog
  };
};
__sfc_main.components = Object.assign({
  LoadingComponent,
  myDialog
}, __sfc_main.components);
export default __sfc_main;
</script>


